import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import AccentLine from '../components/AccentLine';
import BlogPageRecent from '../components/blog/BlogPageRecent';
import CommPageBlogs from '../components/blog/CommPageBlogs';
import Blogs from '../components/blog/Blogs';
import FilterBar from '../components/blog/FilterBar';

const StyledArticle = styled.article`
	padding-top: 15rem;
	padding-bottom: 7.5rem;

	@media (max-width: 768px) {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	.intro-section {
		margin-bottom: 2rem;
		padding: 0 1.5rem;

		@media (max-width: 768px) {
			margin-bottom: 2rem;
		}

		h2 {
			font-size: 3.5rem;
			color: ${(props) => props.theme.colors.tertiary};
			margin-top: 2rem !important;

			@media (max-width: 768px) {
				margin-top: 5rem;
			}
		}
	}

	.past-posts-section {
		padding-top: 10rem;
		padding-bottom: 10rem;

		h2 {
			font-size: 3.5rem;
			position: relative;
			overflow: hidden;
			margin-bottom: 3rem;

			&::after {
				content: '';
				height: 2px;
				width: 100%;
				background-color: ${(props) => props.theme.colors.quaternary};
				position: absolute;
				top: 50%;
				margin-left: 3rem;
				transform: translateY(-50%);
			}
		}
	}
`;

export const TagPageTemplate = ({ blogs, activeTag, tags }) => {
  return (
    <StyledArticle>
      <section className="intro-section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column has-text-centered">
              <h1>Blog</h1>
              <AccentLine align="center" />
              <p className="sub-head">
                Catch up with monthly market updates and enjoy home owner tips
              </p>
              <FilterBar activeTag={activeTag} tags={tags} />
              <h2 style={{ color: "#641823" }}>RESULTS</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="section recent-posts-section">
        <div className="container">
          <Blogs posts={blogs} />
        </div>
      </section>
    </StyledArticle>
  );
};


const TagPage = ({ data }) => {
  const blogs = data.blogs.edges;
  const activeTag = data.tag.frontmatter.tag;
  const tags = data.tags.edges.map(item => ({
    tag: item.node.frontmatter.tag,
    slug: item.node.fields.slug,
  }));
  console.log(tags);
  return (
    <Layout>
      <SEO
        title={"Blog"}
        description="Catch-up with monthly market updates and enjoy helpful tips and tricks to help you as you look to sell your home or buy a new space."
      />
      <TagPageTemplate blogs={blogs} activeTag={activeTag} tags={tags} />
    </Layout>
  );
};

export default TagPage;


export const tagPageQuery = graphql`
  query BlogsByTag($tag: String!) {
    blogs: allMarkdownRemark(filter: {frontmatter: {template_key: {eq: "blog-post"}, tags: {eq: $tag}}}) {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          fields {
            slug
          }
          frontmatter {
            title
            author
            featured_image {
              alt
              image
            }
          }
        }
      }
    }
    tag: markdownRemark(frontmatter: {tag: {eq: $tag}}) {
      fields {
        slug
      }
      frontmatter {
        tag
      }
    }
    tags: allMarkdownRemark(filter: {frontmatter: {template_key: {eq: "tag"}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            tag
          }
        }
      }
    }
  }
`;
